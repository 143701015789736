import './style/Offers.css';
import React, { useEffect, useMemo } from 'react';
import ChipRounded from './ChipRounded';
import Icon from './Icon';
import network from '../../media/network@3x.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openTrayTypeWithData } from '../../redux/keystoneSlice';
import { makeSelectInventoryItemById } from '../../redux/keystoneInventorySlice';
import Row from './Row';
import Placeholder from './Placeholder';
import Label from './Label';
import Button from './Button';
import { useEvent as useEffectEvent } from '@reactuses/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchOffers, normalizeTabName } from '../../redux/keystoneInterfaceSlice';

const Offers = () => { 
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const keystoneInterfaceFetchingCompany = useSelector((state) => state?.keystoneInterface?.fetchingCompany);
    const keystoneInterfaceOffersAccepted = useSelector((state) => state?.keystoneInterface?.offersAccepted);
    const keystoneInterfaceOffersTabs = useSelector((state) => state?.keystoneInterface?.offersTabs);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);

    const { page, tabName } = useParams();

    const keystoneInterfaceOffersTab = keystoneInterfaceOffersTabs.map(normalizeTabName).indexOf(normalizeTabName(tabName));
    useEffect(() => {
        if (keystoneInterfaceOffersTab < 0) {
            navigate(`/${page}/${normalizeTabName(keystoneInterfaceOffersTabs?.[0])}`);
        }
    }, [keystoneInterfaceOffersTab, keystoneInterfaceOffersTabs, page, navigate]);

    const keystoneInventoryOffers = useSelector((state) => state?.keystoneInventory?.offers);

    const { search } = useLocation();
    const selectedObjectId = new URLSearchParams(search).get('id') || null;
    const selectInventoryItemById = useMemo(() => makeSelectInventoryItemById(), []);
    const selectedObject = useSelector(state => selectInventoryItemById(state, selectedObjectId));

    useEffect(() => {
        fetchOfferData();
    }, []);

    const fetchOfferData = useEffectEvent(() => {
        dispatch(fetchOffers({ accessToken: keystoneInterfaceAccessToken }));
    });

    useEffect(() => {
        if (selectedObject) {
            dispatch(openTrayTypeWithData({
                trayOpen: true,
                trayData: selectedObject,
                trayType: "offer"
            }));
        }
    }, [dispatch, selectedObject]);

    return (
        <div className="keystone-offers product-horizontal-container space-between h-gap-l width-100 overflow-auto">
            <div id={page !== "offers" ? "strategy-suggestion" : ""} className="product-vertical-container flex-1 v-gap-l">
                {page !== "offers" && (
                    <div className="product-horizontal-container align-center space-between">
                        <ChipRounded label="Offers" secondary={true} />
                        <Label content={keystoneInterfaceOffersAccepted ? "No Offers Received" : "Not Accepting Offers"} secondary={true} displayFont={true} bold={true} size="s" />
                    </div>
                )}
                {!keystoneInterfaceFetchingCompany && !keystoneInterfaceOffersAccepted && (
                    <div className={`offers-promo product-vertical-container v-gap-l ${page === "offers" && 'margin-32'}`}>
                        <div className="product-horizontal-container h-gap-xs relative space-between">
                            <div className="product-vertical-container v-gap-xs flex-1">
                                <Label content="Inventory Access" size="m" bold={true} displayFont={true} />
                                <Label content="Your inventory is hidden by default from prospective buyers." size="m" />
                            </div>
                            <Icon icon={network} size={28} stylisticFill={true} />
                        </div>
                        <div className="product-horizontal-container justify-end h-gap-l">
                            {/* <Button label="Learn More" onClick={handleModalOpen} onBrand={true} secondary={true} /> */}
                            <Button label="Go to Setting" destinationUrl={"/manage/organization"} onBrand={true} />
                        </div>
                    </div>
                )}
                {keystoneInterfaceOffersAccepted && page === "offers" && (
                    <div className="product-vertical-container list-container pad-m v-gap-s overflow-auto">
                        {keystoneInventoryOffers?.filter(offer =>
                            (keystoneInterfaceOffersTab === 0 && offer.status === "AWAITING_RESPONSE") ||
                            (keystoneInterfaceOffersTab === 1 && offer.status === "ACCEPTED") ||
                            (keystoneInterfaceOffersTab === 2 && offer.status === "COMPLETED") ||
                            (keystoneInterfaceOffersTab === 3 && offer.status === "REJECTED")).map((offer, index) => (
                                <Row
                                    key={offer.id}
                                    index={offer.id}
                                    primaryLabel={`Offer #${offer.id}`}
                                    secondaryLabel={`Quantity: ${offer.total_quantity} - Unique Parts: ${offer.offer_items?.length}`}
                                    navigateOnClick={true}
                                    tags={[{
                                        label: offer.total_price,
                                        secondary: false,
                                        format: "usd"
                                    }]} />
                            )
                        )}
                    </div>
                )}
                {keystoneInterfaceOffersAccepted &&
                page === "offers" &&
                keystoneInterfaceOffersTab === 0 &&
                !keystoneInventoryOffers?.some(offer => (
                    offer.status === "AWAITING_RESPONSE")
                ) && (
                    <Placeholder label="You're all caught up!" />
                )}
                {keystoneInterfaceOffersAccepted &&
                page === "offers" &&
                keystoneInterfaceOffersTab === 1 &&
                !keystoneInventoryOffers?.some(offer => (
                    offer.status === "ACCEPTED")
                ) && (
                    <Placeholder label="You're all caught up!" />
                )}
                {keystoneInterfaceOffersAccepted &&
                page === "offers" &&
                keystoneInterfaceOffersTab === 2 &&
                !keystoneInventoryOffers?.some(offer => (
                    offer.status === "COMPLETED"
                )) && (
                    <Placeholder label="You're all caught up!" />
                )}
                {keystoneInterfaceOffersAccepted &&
                page === "offers" &&
                keystoneInterfaceOffersTab === 3 &&
                !keystoneInventoryOffers?.some(offer => (
                    offer.status === "REJECTED"
                )) && (
                    <Placeholder label="You're all caught up!" />
                )}
            </div>
        </div>
    );
};

export default React.memo(Offers);