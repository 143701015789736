import './style/WarehouseLocations.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWarehouseLocationCounts } from '../../redux/keystoneInventorySlice';
import Label from './Label';
import Row from './Row';
import { setEditingWarehouseName, setModalType, setShowModal } from '../../redux/keystoneInterfaceSlice';
import Button from './Button';

const WarehouseLocations = () => {
    const dispatch = useDispatch();
    const keystoneUser = useSelector((state) => state?.keystone?.user);

    const configuredWarehouseLocations = useSelector((state) => state?.keystoneInterface?.companyWarehouseLocations);
    const warehouseLocationCounts = useSelector(selectWarehouseLocationCounts);
    const allWarehouseNames = Object.keys(warehouseLocationCounts);


    const unconfiguredWarehouseNames = allWarehouseNames.filter(name => !configuredWarehouseLocations?.[name]);

    const showWarehouseAddressModal = (name) => {
        dispatch(setEditingWarehouseName(name));
        dispatch(setModalType("set-warehouse-address"));
        dispatch(setShowModal(true));
    };

    const mapConfiguredAddressToString = (address) => {
        if (!address) {
            return 'No address set.';
        }
        const { name, line1, line2, city, region, postal_code, country } = address;
        return `${name}, ${line1}${line2 ? `, ${line2}` : ''}, ${city}, ${region} ${postal_code}, ${country}`;
    };
    


    return (
        <div className='product-vertical-container v-gap-s flex-1'>
            <div className="product-vertical-container pad-m v-gap-s flex-1">
                <Label content="Warehouse Locations" size="l" bold={true} displayFont={true}/>
                <Label content="Warehouse locations are detected from your uploaded inventory list's Warehouse Name column. You can edit the address for each warehouse location below." size="s" bold={true} displayFont={true} secondary={true}/>
            </div>
            {allWarehouseNames.length > 0 && (<div className="product-vertical-container v-gap-s flex-1">
                {allWarehouseNames.map((name, index) => (
                    <div className='flex-1 keystone-card space-between h-gap-xl pad-m' key={index}>
                        <div className='product-vertical-container location-summary v-gap-xxs'>
                            <Label content={`${name}`} bold={true} displayFont={true} size='s' />
                            <Label content={`Storing ${warehouseLocationCounts[name]} inventory items.`} size='s' mono={true} />
                        </div>
                        <div className='product-vertical-container location-address v-gap-xxs'>
                            <Label content={'Address'} bold={true} displayFont={true} size='s' />
                            <Label content={mapConfiguredAddressToString(configuredWarehouseLocations?.[name]?.address)} size='s' mono={true} />
                        </div>
                        <div className='product-horizontal-containerjustify-end'>
                            {keystoneUser?.role === "ADMIN" && <Button onClick={() => showWarehouseAddressModal(name)} secondary={!!configuredWarehouseLocations?.[name]?.address} brand={!configuredWarehouseLocations?.[name]?.address} label={!!configuredWarehouseLocations?.[name]?.address ? "Edit Address" : "Set Address"} />}
                        </div>
                    </div>
                ))}
            </div>)}
            {allWarehouseNames.length === 0 && (<div className="product-vertical-container v-gap-s flex-1">
                    <div className='flex-1 keystone-card-secondary space-between h-gap-xl pad-m'>
                        <div className='product-vertical-container flex-1 v-gap-xxs'>
                            <Label content={"No warehouse locations"} bold={true} displayFont={true} size='s' />
                            <Label content={`Upload your inventory list to begin managing warehouse locations.`} size='s' mono={true} />
                        </div>
                    </div>
            </div>)}
        </div>
    );
};

export default React.memo(WarehouseLocations);