import './style/Modal.css';
import React, { useEffect, useMemo } from 'react';

import UploadFlow from './UploadFlow';
import InventoryAccessFlow from './InventoryAccessFlow';
import CreateAccount from './CreateAccount';
import TermsAgreement from './TermsAgreement';
import ConfirmModal from './ConfirmModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setShowModal, submitFeedback, setFeedbackSubmitted, setOperationId, setUploadedInventory, setErrorUploadingInventory, setValidationErrors, setCommittedInventory, setHeaderMappings, setSetWarehouseLocationsSuccessfully, setErrorSettingWarehouseLocations, setSettingWarehouseLocations } from '../../redux/keystoneInterfaceSlice';
import InviteModal from './InviteModal';
import BoxMinimumModal from './BoxMinimumModal';
import TextAreaModal from './TextAreaModal';
import SetWarehouseLocationModal from './SetWarehouseLocationModal';
import { useEvent as useEffectEvent } from '@reactuses/core';
import { selectAllInventoryItemsWithData } from '../../redux/keystoneInventorySlice';

const modalTypeConfiguration = {
    "change-user-role": {
        allowDismiss: true,
        allowEscapeDismiss: true,
    },
    "invite-member": {
        allowDismiss: false,
        allowEscapeDismiss: true,
    },
    "delete-user": {
        allowDismiss: true,
        allowEscapeDismiss: true,
    },
    "create-account": {
        allowDismiss: true,
        allowEscapeDismiss: true,
    },
    "create-account-invite": {
        showBlur: true,
    },
    "upload-flow-file": {
        allowDismiss: true,
        allowEscapeDismiss: true,
        showBlur: true,
    },
    "upload-flow-file-update": {
        allowDismiss: false,
        allowEscapeDismiss: true,
        showBlur: true,
    },
    "upload-flow": {
        allowDismiss: false,
        allowEscapeDismiss: false,
        showBlur: true,
    },
    "feedback": {
        allowDismiss: true,
        allowEscapeDismiss: true,
    },
    "set-warehouse-address": {
        allowDismiss: true,
        allowEscapeDismiss: true,
    }
};

const Modal = ({ 
    handleCredentialAccountCreate, 
    handleCredentialAccountCreateFromInvite, 
    handleGoogleAccountCreate, 
    handleGoogleAccountCreateFromInvite }) => {
    const dispatch = useDispatch();
    const keystoneInterfaceShowModal = useSelector((state) => state?.keystoneInterface?.showModal);
    const keystoneInterfaceModalType = useSelector((state) => state?.keystoneInterface?.modalType);
    const keystoneInterfaceFeedbackSubmitted = useSelector((state) => state?.keystoneInterface?.feedbackSubmitted);
    const keystoneInterfaceSubmittingFeedback = useSelector((state) => state?.keystoneInterface?.submittingFeedback);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);

    const keystoneUserToDelete = useSelector((state) => state?.keystone?.userToDelete);
    const keystoneUserToChange = useSelector((state) => state?.keystone?.userToChange);
    const keystoneRoleToChange = useSelector((state) => state?.keystone?.roleToChange);
    const keystoneTrayOpen = useSelector((state) => state?.keystone?.trayOpen);

    const keystoneInventoryTeamMembers = useSelector((state) => state?.keystoneInventory?.team_members);
    
    const keystoneInventoryAll = useSelector(selectAllInventoryItemsWithData);

    useEffect(() => {
        // Function to handle keydown event
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            // Dispatch action to close the modal
            dispatch(setShowModal(false));
          }
        };
 
        // Add event listener when the modal is shown if it allows dismissal
        if (keystoneInterfaceShowModal && modalTypeConfiguration[keystoneInterfaceModalType]?.allowEscapeDismiss) {
          document.addEventListener('keydown', handleKeyDown);
        }
 
        // Clean up the event listener when the component unmounts or modal closes
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [keystoneInterfaceShowModal, dispatch, keystoneInterfaceModalType]);

    useEffect(() => {
        if (keystoneInterfaceModalType === 'feedback' && keystoneInterfaceFeedbackSubmitted) {
            dispatch(setShowModal(false));
            dispatch(setFeedbackSubmitted(false));
        }
    }, [keystoneInterfaceFeedbackSubmitted, keystoneInterfaceModalType, dispatch]);

    const usersName = keystoneInterfaceModalType === "delete-user" ?
        (keystoneUserToDelete?.first_name ? `${keystoneUserToDelete?.first_name} ${keystoneUserToDelete?.last_name}` : keystoneUserToDelete?.email) :
        keystoneInterfaceModalType === "change-user-role" ?
        (keystoneUserToChange?.first_name ? `${keystoneUserToChange?.first_name} ${keystoneUserToChange?.last_name}` : keystoneUserToChange?.email) :
        "";

    const handleSubmitFeedback = useEffectEvent((feedback) => {
        dispatch(submitFeedback({ feedback, accessToken: keystoneInterfaceAccessToken }));
    });

    const handleModalDismiss = () => {
        dispatch(setShowModal(false));
        if (keystoneInterfaceModalType?.includes('upload-flow')) {
            dispatch(setOperationId(null));
            dispatch(setUploadedInventory(null));
            dispatch(setErrorUploadingInventory(null));
            dispatch(setValidationErrors(null));
            dispatch(setCommittedInventory(null));
            dispatch(setHeaderMappings(null));
        } else if (keystoneInterfaceModalType === "set-warehouse-address") {
            dispatch(setSetWarehouseLocationsSuccessfully(false));
            dispatch(setErrorSettingWarehouseLocations(null));
            dispatch(setSettingWarehouseLocations(false));
        }
    };

    const handleSaveLocations = (locations) => {
        console.log(locations);
        dispatch(setShowModal(false));
    };

    const uniqueLocations = useMemo(() => {
        if (keystoneInterfaceModalType === "locations-setup") {
            const uniqueLocations = keystoneInventoryAll?.reduce((acc, item) => {
                if (!acc.includes(`${item.warehouse_name}`)) {
                    acc.push(`${item.warehouse_name}`);
                }
                return acc;
            }, []);
            return uniqueLocations;
        };
    }, [keystoneInterfaceModalType, keystoneInventoryAll]);

    return keystoneInterfaceShowModal && (
        <div id="keystone-modal" className={`${modalTypeConfiguration[keystoneInterfaceModalType]?.showBlur ? "blur" : ""} ${keystoneTrayOpen ?  "minus-480" : ""}`} onClick={(e) => {
                if (e.target.id === "keystone-modal" && modalTypeConfiguration[keystoneInterfaceModalType]?.allowDismiss) {
                    handleModalDismiss();
                }
            }}>
            {keystoneInterfaceShowModal && keystoneInterfaceModalType?.includes("upload-flow") && (
            <UploadFlow isUpdate={keystoneInterfaceModalType === "upload-flow-file-update"} handleDismiss={() => handleModalDismiss()}/>
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "inventory-access" && (
                <InventoryAccessFlow  />
            )}
            {keystoneInterfaceShowModal && (keystoneInterfaceModalType === "create-account" || keystoneInterfaceModalType === "create-account-invite")  && (
                <CreateAccount
                    handleCredentialAccountCreate={handleCredentialAccountCreate}
                    handleCredentialAccountCreateFromInvite={handleCredentialAccountCreateFromInvite}
                    handleGoogleAccountCreate={handleGoogleAccountCreate}
                    handleGoogleAccountCreateFromInvite={handleGoogleAccountCreateFromInvite} />
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "tos" && (
                <TermsAgreement />
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "delete-user" && (
                <ConfirmModal
                    confirmText="Delete User"
                    confirmTextDisabled="Cannot Delete User"
                    contextText={`Are you sure you want to remove ${usersName} from your organization?`}
                    contextTextDisabled={`You cannot delete ${usersName} because they are the only Admin in the organization.`}
                    disabled={keystoneInventoryTeamMembers?.filter(member => member.role === "ADMIN")?.length === 1 && (keystoneInventoryTeamMembers?.filter(member => member.role === "ADMIN")?.[0]?.email === keystoneUserToDelete?.email)}
                    primaryButtonText="Yes, Delete User"
                    primaryButtonDisabledText="Ok"
                    secondaryButtonText="No, Cancel" />
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "invite-member" && (
                <InviteModal />
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "box-minimum" && (
                <BoxMinimumModal />
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "feedback" && (
                <TextAreaModal
                    title="Give Feedback"
                    cta="Send Feedback"
                    inputLabel="Feedback:"
                    inputPlaceholder="Enter your feedback here"
                    onSubmit={(feedback) => handleSubmitFeedback(feedback)}
                    ctaLoading={keystoneInterfaceSubmittingFeedback}
                />
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "change-user-role" && (
                <ConfirmModal
                    confirmText="Change User Role"
                    confirmTextDisabled="Cannot Change User Role"
                    contextText={`Are you sure you want to change ${usersName}'s role to ${keystoneRoleToChange === "VIEWER" ? "Viewer" : "Admin"}?`}
                    contextTextDisabled={`You cannot change ${usersName}'s role because they are the only Admin in the organization.`}
                    disabled={keystoneInventoryTeamMembers?.filter(member => member.role === "ADMIN")?.length === 1 && (keystoneInventoryTeamMembers?.filter(member => member.role === "ADMIN")?.[0]?.email === keystoneUserToChange?.email)}
                    primaryButtonText="Yes, Change Role"
                    primaryButtonDisabledText="Ok"
                    secondaryButtonText="No, Cancel" />
            )}
            {keystoneInterfaceShowModal && keystoneInterfaceModalType === "set-warehouse-address" && (
                <SetWarehouseLocationModal handleDimiss={() => handleModalDismiss()} />)}
        </div>
    );
};

export default React.memo(Modal);