import './style/ScoreBar.css';
import React from 'react';

const ScoreBar = ({ score, gold, invert, label, minLabel, maxLabel, flex = true, padLeft = false, padRight = false }) => {
    const fillWidth = Math.min(score * 100, 100);
    const color = fillWidth > 50 ? "#FFCC16" : "#000";
    
    return (
        <div className={`product-horizontal-container h-gap-m flex-1 align-center relative ${flex ? "flex-1" : "max-160"} ${padRight === true ? "pad-right-24" : ""} ${padLeft === true ? "pad-left-24" : ""}`}>
          {minLabel && <p className="score-bar-label-min sf-mono size-s medium uppercase">{minLabel}</p>}
          <div className={`flex-1 align-center relative ${invert && 'invert'}`}>
              <div className="bar-background">
                <div className="bar-fill" style={{ width: `${fillWidth}%`, backgroundColor: color }} />
              </div>
              {label && <p className="score-bar-label size-s medium sf-mono uppercase">{label}</p>}
          </div>
          {maxLabel && <p className="score-bar-label-max sf-mono size-s medium uppercase">{maxLabel}</p>}
        </div>
    );
};

export default React.memo(ScoreBar);