import './style/Manage.css';
import React, { useEffect } from 'react';
import SettingSection from './ManageSection';
import { useSelector } from 'react-redux';
import Divider from './Divider';
import Label from './Label';
import { useNavigate, useParams } from 'react-router-dom';
import { normalizeTabName } from '../../redux/keystoneInterfaceSlice';
import WarehouseLocations from './WarehouseLocations';
import { formatToCurrency } from '../../util/Calculate';

const Manage = () => {
    const keystoneInterfaceBoxMinimum = useSelector((state) => state?.keystoneInterface?.boxMinimum);
    const keystoneInventoryBankAccounts = useSelector((state) => state?.keystoneInventory?.bank_ccounts);
    const keystoneInterfaceManageTabs = useSelector((state) => state?.keystoneInterface?.manageTabs);
    const keystoneUser = useSelector((state) => state?.keystone?.user);

    const { page, tabName } = useParams();
    const navigate = useNavigate();
    
    const keystoneInterfaceManageTab = keystoneInterfaceManageTabs?.map(normalizeTabName).indexOf(normalizeTabName(tabName));
    useEffect(() => {
        if (keystoneInterfaceManageTab < 0) {
            navigate(`/${page}/${normalizeTabName(keystoneInterfaceManageTabs?.[0])}`);
        }
    }, [keystoneInterfaceManageTab, keystoneInterfaceManageTabs, page, navigate]);

    const manage = {
        organization: {
            // company: [{
            //     state: "companyName",
            //     primaryLabel: keystoneInterface?.user?.company?.name || "",
            //     secondaryLabel: "The name of your company.",
            //     ctaLabel: "Edit Details",
            // }],
            offers: [{
                id: "o1",
                state: "offersAccepted",
                ctaLabel: "Enable Offers",
                ctaLabelAlternate: "Disable Offers",
                primaryLabel: "Receive Offers",
                primaryLabelAlternate: "Receive Offers",
                secondaryLabel: "Your inventory is hidden to prospective buyers by default. When enabled, offers can be made on items with no commitment to accept.",
                secondaryLabelAlternate: "Your inventory is able to receive offers by prospective buyers with no commitment to accept.",
                disabled: keystoneUser?.role === 'VIEWER'
            },{
                id: "o2",
                state: "boxMinimum",
                ctaLabel: "Set Minimum Shipment Value",
                ctaLabelAlternate: "Edit Minimum Shipment Value",
                primaryLabel: "Minimum Shipment Value",
                primaryLabelAlternate: "Minimum Shipment Value",
                secondaryLabel: "Set a minimum shipment value to filter out small offers.",
                secondaryLabelAlternate: `You have set a minimum shipment value of ${formatToCurrency(keystoneInterfaceBoxMinimum || 0)} `,
                disabled: keystoneUser?.role === 'VIEWER'
            }],
            payouts: [{
                id: "o3",
                state: "payoutsSetup",
                ctaLabel: "Connect Bank Account",
                ctaLabelAlternate: `Disconnect Bank Account (*${keystoneInventoryBankAccounts?.[0]?.account_mask})`,
                primaryLabel: "Receive Payouts",
                primaryLabelAlternate: "Receive Payouts",
                secondaryLabel: "You have not connected a bank account to receive payouts.",
                secondaryLabelAlternate: "You have connected a bank account to receive payouts.",
                disabled: keystoneUser?.role === 'VIEWER'
            }]
        },
        notifications: {
            offers: [{
                id: "n1",
                state: "offersNotify",
                ctaLabel: "Enable Notifications",
                ctaLabelAlternate: "Disable Notifications",
                primaryLabel: "Receive Email Notifications for Offers",
                primaryLabelAlternate: "Receive Email Notifications for Offers",
                secondaryLabel: "You are not receiving notifications for offers.",
                secondaryLabelAlternate: "You are receiving notifications for offers.",
                disabled: keystoneUser?.role === 'VIEWER'
            }],
        },
        documents: {
            taxes: [{
                id: "d1",
                primaryLabel: "No Documents Available",
                secondaryLabel: "Tax documents will appear here when generated.",
                placeholder: true
            }],
            statements: [{
                id: "d1",
                primaryLabel: "No Documents Available",
                secondaryLabel: "Statements will appear here when generated.",
                placeholder: true
            }],
        }
    };

    return (
        <div id="keystone-manage">
            <div className="product-vertical-container list-container pad-m no-pad-bot v-gap-s">
                {keystoneInterfaceManageTab === 0 && Object.keys(manage.organization).map((section, index) => (
                    <SettingSection
                        key={section.state}
                        name={section[0].toUpperCase() + section.slice(1)}
                        manage={manage.organization[section]}
                        />
                ))}
                {keystoneInterfaceManageTab === 1 && <WarehouseLocations />}
                {keystoneInterfaceManageTab === 2 && manage.notifications && Object.keys(manage.notifications).map((section, index) => (
                    <SettingSection
                        key={section.state}
                        name={section[0].toUpperCase() + section.slice(1)}
                        manage={manage.notifications[section]}
                        />
                ))}
                {keystoneInterfaceManageTab === 3 && manage.documents && Object.keys(manage.documents).map((section, index) => (
                    <SettingSection
                        key={section.state}
                        name={section[0].toUpperCase() + section.slice(1)}
                        manage={manage.documents[section]}
                        />
                ))}
            </div>
            <div className="product-vertical-container pad-xl v-gap-xl">
                <Divider vertical={false} />
                <Label content={
                    <>
                        Please contact <a href="mailto:support@trymaterialize.com">support@trymaterialize.com</a> for help with your account.
                    </>
                } size="s" bold="true" secondary={true} displayFont={true} />
            </div>
        </div>
    );
};

export default React.memo(Manage);