import './style/Login.css';
import React, { useState, useEffect } from 'react';
import google from '../media/google.png';
import Label from '../component/keystone/Label';
import Divider from '../component/keystone/Divider';
import { setAuthRedirectPage, fetchMagicLinkAccessToken, setErrorFetchingAccessToken, setMagicLinkCode } from '../redux/keystoneSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Wordmark from '../media/materialize-logo-wordmark-black.png';
import RightArrow from '../media/right-arrow-32@3x.png';
import SimpleLoader from '../component/keystone/SimpleLoader';

const Login = () => {
    const [userEmail, setUserEmail] = useState(null);
    const { loginWithRedirect, error } = useAuth0();
    const dispatch = useDispatch();
    const [inviteCode, setInviteCode] = useState(null);
        
    const fetchingAccessToken = useSelector((state) => state?.keystone?.fetchingAccessToken);
    const keystoneErrorFetchingAccessToken = useSelector((state) => state?.keystone?.errorFetchingAccessToken);

    const handleLoginWithEmail = async () => {
        dispatch(setAuthRedirectPage('inventory'));
        return await loginWithRedirect({
            authorizationParams: {
                connection: 'Username-Password-Authentication',
                login_hint: userEmail || '',
            }
        });
    };

    const handleLoginWithGoogle = async () => {
        dispatch(setAuthRedirectPage('inventory')); // go to inventory after login
        return await loginWithRedirect({
            authorizationParams: {
                connection: 'google-oauth2'
            }
        });
    };

    const handleInviteCodeLogin = () => {
        dispatch(setMagicLinkCode(inviteCode));
        dispatch(fetchMagicLinkAccessToken({ code: inviteCode }));
    };

    useEffect(() => {
        if (inviteCode?.length > 0) {
            dispatch(setErrorFetchingAccessToken(null));
        }
    }, [dispatch, inviteCode]);
    
    return (
        <div id="login">
            <img src={Wordmark} alt="Materialize Wordmark" className="wordmark-image" onClick={() => window.location.href = "/"} />
            <div id="login-wrapper">
                <div id="login-content-wrapper">
                    <div id="login-header-wrapper" className="yellow-bg product-vertical-container v-gap-xs pad-xl">
                        <Label content="Login" size="display" bold={true} color="black" />
                        <Label content="Market insights and reclamation dashboard." size="m" mono={true} color="black" />
                    </div>
                    <div id="login-details-wrapper" className="product-vertical-container v-gap-l grey-bg pad-xl">
                        <div className="product-vertical-container flex-1 width-100 v-gap-m">
                            <input id="create-account-email-input" className="sf-mono size-m" type="email" placeholder="Email Address" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}/>
                            <button className="create-account-button" onClick={handleLoginWithEmail}>
                                <Label content="Login" size="m" bold={true} displayFont={true} />
                            </button>
                        </div>
                        <Divider label="or" />
                        <button id="login-google-button" onClick={handleLoginWithGoogle}>
                            <img src={google} alt="Google's 'G' Logo"/>
                            <Label content="Sign in with Google" size="m" bold={true} displayFont={true} />
                            <img src={google} alt="Google's 'G' Logo"/>
                        </button>
                        <Label content="Access your dashboard using your organization email address." size="s" line="s" bold={true} secondary={true} displayFont={true} center={true} />
                    </div>
                    <Divider vertical={false} />
                    <div className="product-vertical-container pad-l-xl pad-r-xl pad-t-m pad-b-m grey-bg v-gap-m">
                        <div className="grey-bg flex flex-1 round-s horizontal h-gap-xl">
                            <Label content="Have an invite code?" size="s" line="s" bold={true} secondary={true} displayFont={true} center={true} maxContent={true} />
                            <div className="flex flex-1 h-gap-s horizontal">
                                <input className="sf-mono size-m flex-1 white-bg small" type="text" placeholder="Enter Code" size={1} value={inviteCode} onChange={(e) => setInviteCode(e.target.value)} />
                                <button className="invite-code-button relative" disabled={inviteCode === null || inviteCode === ""} onClick={handleInviteCodeLogin}>
                                    <img alt='Right arrow' src={RightArrow} style={{width: 20, height: 20}} />
                                    <SimpleLoader loading={fetchingAccessToken} />
                                </button>
                            </div>
                        </div>
                        {(keystoneErrorFetchingAccessToken === "LINK_EXPIRED" && inviteCode?.length > 0) && (
                            <Label content="Your invite code has expired, please reach out to info@trymaterialize to receive another!" size="s" line="s" displayFont={true} center={true} classes="pad-s round-s red-bg red-text" />
                        )}
                        {(keystoneErrorFetchingAccessToken === "LINK_CONVERTED" && inviteCode?.length > 0) && (
                            <Label content="Looks like you've already created your account, try logging in above!" size="s" line="s" displayFont={true} center={true} color="black" classes="pad-s round-s green-bg green-text" />
                        )}
                        {(keystoneErrorFetchingAccessToken === "NOT_FOUND" && inviteCode?.length > 0) && (
                            <Label content="Hmm, we can't find that code!" size="s" line="s" bold={true} displayFont={true} center={true} classes="pad-s round-s red-bg red-text" />
                        )}
                    </div>
                    <div id="login-footer-wrapper" className={error ? "cta" : ""}>
                        <p id="login-redirect" className="size-s bold secondary">Not a customer? Check out <a href="https://trymaterialize.com">what we do here</a>.</p>
                    </div>
                </div>
                <a href='/privacy' id="login-privacy-anchor" className="size-s secondary">Privacy Policy</a>
            </div>
        </div>
    );
};

export default Login;