import './style/Button.css';
import React from 'react';
import LoaderBlock from './LoaderBlock';
import Icon from './Icon';
import SimpleLoader from './SimpleLoader';
import Label from './Label';
import { useNavigate } from 'react-router-dom';

const Button = ({
    customId,
    reference,
    label,
    icon,
    iconColor = "#b9bfd6",
    iconHoverColor = "#6f7aa5",
    image,
    iconSize = 24,
    large,
    loading,
    showAlternate,
    onClick,
    destinationUrl,
    brand,
    secondary,
    onBrand,
    flex = false,
    tooltipContent,
    showTooltipContent,
    textColor = "black",
    disabled = false,
    transparent = false,
    small = false,
    size,
    classes }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        if (onClick) {
            onClick(e);
        } else if (destinationUrl) {
            navigate(destinationUrl);
        }
    };
    return (<button
            id={customId}
            className={`ks-button ${large ? 'large' : ""} ${!label ? 'rounded' : ""} ${loading ? "loading" : ""} ${showAlternate ? "alternate" : ""} ${brand && secondary ? "brand-secondary" : brand ? "brand" : ""} ${onBrand ? "on-brand" : ""} ${secondary ? "secondary" : ""} ${flex ? "flex-1" : "max-content"} ${tooltipContent ? "relative" : ""} ${size ? "b-size-" + size : ""} align-center justify-center h-gap-xs ${transparent ? "transparent" : ""} ${small ? "small" : ""} ${classes}`}
            disabled={disabled}
            ref={reference}
            onClick={handleClick}>
                {image && <img alt='' src={image} style={{width: iconSize + "px", height: iconSize + "px"}} />}
                {icon && <Icon icon={icon} size={iconSize} color={iconColor} hoverColor={iconHoverColor} />}
                {label && <Label content={label} size={size ? size : large ? "m" : "s"} bold={true} displayFont={true} color={textColor} overflowEllipsis={true} />}
                {loading && <LoaderBlock />}
                {tooltipContent && showTooltipContent && tooltipContent}
                {loading && <SimpleLoader loading={loading} size={large ? 28 : 24} />}
        </button>
    );
};

export default React.memo(Button);