import './style/Content.css';
import React, { useState } from 'react';
import InventoryList from './InventoryList';
import SearchIssueDisplay from '../../media/search-issue-display@3x.png';
import Speaker from '../../media/speaker32px@3x.png';
import UpArrow from '../../media/up-arrow@3x.png';
import Icon from './Icon';
import Manage from './Manage';
import Offers from './Offers';
import Shipments from './Shipments';
import Payouts from './Payouts';
import Team from './Team';
import { useDispatch } from 'react-redux';
import { setTrayOpen } from '../../redux/keystoneSlice';
import { setModalType, setShowModal } from '../../redux/keystoneInterfaceSlice';
import { useSelector } from 'react-redux';
import TabGroup from './TabGroup';
import Input from './Input';
import Button from './Button';
import Label from './Label';
import SimpleLoader from './SimpleLoader';
import InventorySummary from './InventorySummary';
import { selectAllInventoryItemsWithData } from '../../redux/keystoneInventorySlice';
import { useParams } from 'react-router-dom';

const Content = () => {
    const keystoneInventoryAll = useSelector(selectAllInventoryItemsWithData);

    const keystoneInterfaceIsLoggedIn = useSelector((state) => state?.keystoneInterface?.isLoggedIn);
    const keystoneInterfaceOffersTabs = useSelector((state) => state?.keystoneInterface?.offersTabs);
    const keystoneInterfacePayoutsTabs = useSelector((state) => state?.keystoneInterface?.payoutsTabs);
    const keystoneInterfaceTeamTabs = useSelector((state) => state?.keystoneInterface?.teamTabs);
    const keystoneInterfaceShipmentsTabs = useSelector((state) => state?.keystoneInterface?.shipmentsTabs);
    const keystoneInterfaceManageTabs = useSelector((state) => state?.keystoneInterface?.manageTabs);
    const keystoneUser = useSelector((state) => state?.keystone?.user);

    const { page } = useParams();
    const dispatch = useDispatch();
    const [searchInputValue, setSearchInputValue] = useState("");

    const handleSearchInputChange = (e) => {
        e.preventDefault();
        setSearchInputValue(e.target.value);
    };

    const handleInviteMember = () => {
        if (!keystoneInterfaceIsLoggedIn) {
            dispatch(setModalType("create-account"));
            dispatch(setShowModal(true));
        } else {
            dispatch(setModalType("invite-member"));
            dispatch(setShowModal(true));
        }
    };

    const handleShowUploadFlow = () => {
        if (keystoneInterfaceIsLoggedIn || !hasUploaded) {
            dispatch(setTrayOpen(false));
            dispatch(setModalType("upload-flow-file-update"));
            dispatch(setShowModal(true));
        } else {
            dispatch(setModalType("create-account"));
            dispatch(setShowModal(true));
        }
    };

    const handleGiveFeedback = () => {
        dispatch(setModalType("feedback"));
        dispatch(setShowModal(true));
    };
    const hasUploaded = !(keystoneInventoryAll?.length === 1 && keystoneInventoryAll?.[0]?.id === 'MTLRZ-KTT-SR-123') && keystoneInventoryAll?.length > 0;

    const showUploadButton = (!keystoneInterfaceIsLoggedIn && !hasUploaded);

    return (
        <div className="product-vertical-container flex-1 overflow-auto">
            <div id="content-header" className="align-center product-horizontal-container pad-xl no-pad-bot h-gap-m space-between">
                <div id="desktop-page-title" className="product-horizontal-container align-center h-gap-l flex-1">
                    <Label content={`${page?.charAt(0)?.toUpperCase()}${page?.slice(1)}`} size="m" bold="true" displayFont={true} />
                </div>
                {page !== "inventory" && (
                    <div id="content-tab" className="product-horizontal-container align-center h-gap-l flex-1">
                        <TabGroup tabs={
                            page === "offers" ? keystoneInterfaceOffersTabs :
                            page === "payouts" ? keystoneInterfacePayoutsTabs :
                            page === "team" ? keystoneInterfaceTeamTabs :
                            page === "shipments" ? keystoneInterfaceShipmentsTabs :
                            page === "manage" ? keystoneInterfaceManageTabs : []
                        } navigation={true} />
                    </div>
                )}
                {page === "inventory" && (
                    <div id="inventory-search-wrapper" className="product-horizontal-container h-gap-s flex-1 align">
                        <Input placeholder="Search" type="text" flex={true} onChange={(e) => handleSearchInputChange(e)} />
                    </div>
                )}
                <div className="product-horizontal-container align-center h-gap-s flex-1 justify-end">
                    {(page === "inventory" && !showUploadButton && keystoneUser?.role === "ADMIN") && (
                        <Button label="Update Inventory" brand={true} onClick={handleShowUploadFlow} icon={UpArrow} iconSize={16} iconColor="black" />
                    )}
                    {(page === "inventory" && showUploadButton && (
                        <Button label="Upload Inventory" brand="true" onClick={handleShowUploadFlow} icon={UpArrow} iconSize={16} iconColor="black"/>
                    ))}
                    {(page === "team" && keystoneUser.role === "ADMIN") && (
                        <Button label="Invite" brand="true" onClick={handleInviteMember} />
                    )}
                    <Button label="Give Feedback" secondary={true} icon={Speaker} iconSize={16} iconColor="black" onClick={handleGiveFeedback} />
                </div>
            </div>
            <div id="keystone-content" className="flex-1 relative">
                {page === "inventory" ? (
                    <div className="product-vertical-container overflow-auto flex-1">
                        <InventorySummary />
                        <InventoryList
                            searchInputValue={searchInputValue}
                        />
                    </div>
                ) : page === "manage" ? (
                    <Manage />
                ) : page === "offers" ? (
                    <Offers />
                ) : page === "shipments" ? (
                    <Shipments />
                ) : page === "payouts" ? (
                    <Payouts />
                ) : page === "team" ? (
                    <Team />
                ) : (
                    <div className="product-vertical-container pad-l align-center flex-1 justify-center">
                        <Icon icon={SearchIssueDisplay} size={120} />
                        <Label content="Hmm... something went wrong" size="l" bold={true} secondary={true} />
                    </div>
                )}
                <SimpleLoader determineLoadingStateAutomatically={true} size={48} />
            </div>
        </div>
    );
};

export default Content;
