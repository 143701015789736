import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import LogRocket from 'logrocket';
import store from './redux/store';
import { Provider } from 'react-redux';

const local = window.location.origin.includes('localhost') ? true : false;

const root = ReactDOM.createRoot(document.getElementById('root'));

if (!local) {
  LogRocket.init('o4yyvv/materialize-app');
};

root.render(
    <Auth0Provider
        domain={local ? "dev-s6tdx5li201sdtoo.us.auth0.com" : "auth.trymaterialize.com"}
        issuer={local ? "https://dev-s6tdx5li201sdtoo.us.auth0.com/" : "https://auth.trymaterialize.com/"}
        clientId={local ? "mY0Y8U7HI15bjzLZGisO2QPw3nBiXxCr" : "9aQMlY78eXKKSwsfEqn6YjzVpxpah4Zp"}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: local ? 'https://dev-s6tdx5li201sdtoo.us.auth0.com/api/v2/' : 'https://trymaterialize.us.auth0.com/api/v2/',
          scope: "openid profile email offline_access"
        }}
      >
        <Provider store={store}>
          <App />
        </Provider>
    </Auth0Provider>
);
