import './style/InventoryList.css';
import React, { useCallback, useEffect, useMemo } from 'react';
import InventoryHeaderColumn from './InventoryHeaderColumn';
import { useSelector } from 'react-redux';
import { openTrayTypeWithData } from '../../redux/keystoneSlice';
import { useDispatch } from 'react-redux';
import { formatToCurrency, formatToCurrencyExpanded, formatNumberWithCommas } from '../../util/Calculate';
import InfoCircle from '../../media/note24@3x.png';
import { makeSelectInventoryItemById, selectAllInventoryItemsWithData } from '../../redux/keystoneInventorySlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GroupedVirtuoso } from 'react-virtuoso';
import Label from './Label';
import ChipRounded from './ChipRounded';
import HeaderVisual from './HeaderVisual';
import { useEvent as useEffectEvent } from '@reactuses/core';

const columns = [
    "Internal Item",
    "Internal Value",
    "Market Match",
    "Market Value",
];

const InventoryList = ({ searchInputValue }) => {
    const keystoneUserPreferencesSortBy = useSelector((state) => state?.keystone?.userPreferences?.sortBy);
    const { pathname } = useLocation();
    const { page } = useParams();
    const navigate = useNavigate();
    
    const { search } = useLocation();
    const selectedObjectId = new URLSearchParams(search).get('id') || null;

    const selectInventoryItemById = useMemo(() => makeSelectInventoryItemById(), []);
    const selectedObject = useSelector(state => selectInventoryItemById(state, selectedObjectId));
    
    const keystoneInterfaceFilteredInventoryIds = useSelector((state) => state?.keystoneInterface?.filteredInventoryIds);
    const keystoneInventoryAll = useSelector(selectAllInventoryItemsWithData);

    const dispatch = useDispatch();

    const sortedInventoryData = useMemo(() => {
        // TODO: Kill cameron for allowing this to happen
        let filteredInventory = searchInputValue?.length > 0 
            ? [...keystoneInventoryAll || []]?.filter(item => 
                item.mpn?.toLowerCase()?.includes(searchInputValue?.toLowerCase()) 
                || item.manufacturer?.toLowerCase().includes(searchInputValue?.toLowerCase())
                || item.manufacturer?.toLowerCase().includes(searchInputValue?.toLowerCase())
                || item.warehouse_name?.toLowerCase().includes(searchInputValue?.toLowerCase())
                || `${item.quantity ?? ''}`.includes(searchInputValue?.toLowerCase()?.replace(',', '')?.replace('$', ''))
                || `${item.unit_value ?? ''}`.includes(searchInputValue?.toLowerCase()?.replace(',', '')?.replace('$', ''))
                || `${((item.quantity ?? 0) * (item.unit_value ?? 0))}`.includes(searchInputValue?.toLowerCase()?.replace(',', '')?.replace('$', ''))
                || (!item.associated_part && item?.normalization_status === "NO_MATCH" && "No Match".toLowerCase().includes(searchInputValue?.toLowerCase()))
                || (!item.associated_part && item?.normalization_status !== "NO_MATCH" && "Processing".toLowerCase().includes(searchInputValue?.toLowerCase()))
                || (item.associated_part && item.associated_part?.mpn?.toLowerCase().includes(searchInputValue?.toLowerCase()))
                || (item.associated_part && item.associated_part?.manufacturer?.toLowerCase().includes(searchInputValue?.toLowerCase()))
                || (item.associated_part && item.associated_part?.short_description?.toLowerCase().includes(searchInputValue?.toLowerCase()))
                || (item.associated_part && item.associated_part?.lowest_market_price && `${item.associated_part?.lowest_market_price}`.includes(searchInputValue?.toLowerCase()?.replace(',', '')?.replace('$', '')))
                || (item.associated_part && item.associated_part?.lowest_market_price && `${(item.associated_part?.lowest_market_price * item.quantity)}`.includes(searchInputValue?.toLowerCase()?.replace(',', '')?.replace('$', '')))
            )
            : [...keystoneInventoryAll || []];
        filteredInventory = filteredInventory.filter(item => !keystoneInterfaceFilteredInventoryIds?.length || keystoneInterfaceFilteredInventoryIds?.includes(item.id));
        if (filteredInventory?.length > 0 && keystoneUserPreferencesSortBy && columns.includes(keystoneUserPreferencesSortBy.replace("-ascending", ""))) {
            filteredInventory = [...filteredInventory].sort((a, b) => {
                const aValue = keystoneUserPreferencesSortBy?.includes('Score') ? a?.computed?.score_clamped :
                    keystoneUserPreferencesSortBy?.includes('Internal Item') ? a?.mpn?.toLowerCase() :
                    keystoneUserPreferencesSortBy?.includes('Internal Value') ? a?.unit_value * a?.quantity :
                    keystoneUserPreferencesSortBy?.includes('Market Match') ? a?.associated_part?.mpn?.toLowerCase() :
                    keystoneUserPreferencesSortBy?.includes('Market Value') ? a?.associated_part?.lowest_market_price * a?.quantity :
                    undefined;
                const bValue = keystoneUserPreferencesSortBy?.includes('Score') ? b?.computed?.score_clamped :
                    keystoneUserPreferencesSortBy?.includes('Internal Item') ? b?.mpn?.toLowerCase() :
                    keystoneUserPreferencesSortBy?.includes('Internal Value') ? b?.unit_value * b?.quantity : 
                    keystoneUserPreferencesSortBy?.includes('Market Match') ? b?.associated_part?.mpn?.toLowerCase() :
                    keystoneUserPreferencesSortBy?.includes('Market Value') ? b?.associated_part?.lowest_market_price * b?.quantity :
                    undefined;

                if ((aValue === undefined || (typeof aValue !== 'string' && isNaN(aValue))) && (bValue === undefined || (typeof bValue === 'string' && isNaN(bValue)))) {
                    return 0; 
                }
                if (aValue === undefined || (typeof aValue !== 'string' && isNaN(aValue))) {
                    return 1; 
                }
                if (bValue === undefined || (typeof aValue !== 'string' && isNaN(bValue))) {
                    return -1; 
                }

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue);
                }

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue);
                } else {
                    return bValue - aValue;
                }
            });

            if (keystoneUserPreferencesSortBy?.includes("-ascending")) {
                filteredInventory.reverse();
            }
        }
        return filteredInventory;
    }, [keystoneInventoryAll, keystoneInterfaceFilteredInventoryIds, keystoneUserPreferencesSortBy, searchInputValue]);


    const navigateOnClick = useEffectEvent((id) => {
        const params = new URLSearchParams();
        params.set('id', id);
        navigate(`${page ? `/${page}` : pathname}?${params.toString()}`);
    });

    const headerContent = useMemo(() => (() => (
        <div id="keystone-inventory-header-wrapper">
            <div id="keystone-inventory-header" className="product-horizontal-container">
                {columns.map((column, index) => (
                    <InventoryHeaderColumn column={index + 1} key={index} label={column} flex={index % 2 === 0} lastColumn={index === columns.length - 1} hideMobile={index !== 0 ? index !== columns.length - 1 : false} />
                ))}
            </div>
        </div>)), []);

    const mapIndexToComponent = useCallback(index => {
        const item = sortedInventoryData[index] || {};
        return (
            // Row
            <div onClick={() => navigateOnClick(item.id)} className='flex-1 keystone-card keystone-row clickable space-between pad-m' key={item.id || index}>
                
                {/* Column 1 */}
                <div className='product-vertical-container inventory-column relaxed-width first v-gap-xxs'>
                    <div className={`product-horizontal-container align-center h-gap-xs`}>
                        <Label content={`${item.mpn}`} bold={true} mono={true} size='s' />
                        <HeaderVisual
                            key={`${item.id || index}-mpn-tooltip`}
                            index={index}
                            displayStyle={false}
                            tooltip={item?.description}
                            icon={InfoCircle}/>
                    </div>
                    <Label content={`${item.manufacturer} (${item.warehouse_name})`} size='s' mono={true} />
                </div>

                {/* Column 2 */}
                <div className='product-vertical-container align-end inventory-column strict-width v-gap-xxs'>
                    <Label content={item.unit_value && item.quantity ? formatToCurrency(item.unit_value * item.quantity) : null} bold={true} mono={true} size='s' />
                    <Label content={`${formatNumberWithCommas(item?.quantity)} x ${formatToCurrencyExpanded(item?.unit_value)}`} size='s' mono={true} />
                </div>

                {/* Column 3 */}
                <div className='product-horizontal-container align-center inventory-column relaxed-width'>
                    {item.associated_part && (
                    <div className='product-vertical-container v-gap-xxs'>
                        <div className={`product-horizontal-container align-center h-gap-xs`}>
                            <Label content={item.associated_part ? `${item.associated_part?.mpn}` : null} bold={true} mono={true} size='s' />
                            <HeaderVisual
                                key={`${item.id || index}-associated-part-tooltip`}
                                index={index}
                                displayStyle={false}
                                tooltip={item?.associated_part?.short_description}
                                icon={InfoCircle}/>
                            {!!(item.associated_part?.ending_production || item.associated_part?.ended_production) && 
                                (<ChipRounded
                                    key={`${item.id || index}-end-production`}
                                    index={index}
                                    label={item.associated_part?.ending_production ? "End of Life" : "Ended Production"}
                                    positive={true}
                                    secondary={false}
                                    small={true}/>)}
                        </div>
                        <Label content={item.associated_part?.manufacturer} size='s' mono={true} />
                    </div>
                    )}
                    {!item.associated_part && item?.normalization_status !== "NO_MATCH" && (
                        <Label content="Processing" size="s" bold={true} mono={true} secondary={true} />
                    )}
                    {!item.associated_part && item?.normalization_status === "NO_MATCH" && (
                        <Label content="No Match" size="s" bold={true} mono={true} secondary={true} />
                    )}
                </div>

                {/* Column 4 */}
                <div className='product-horizontal-container justify-end align-center inventory-column strict-width last'>
                    {item.associated_part && item.associated_part?.lowest_market_price && item.quantity && (
                    <div className='product-vertical-container align-end v-gap-xxs'>
                        <Label content={(item.associated_part && item.associated_part?.lowest_market_price && item.quantity) ? formatToCurrency(item.associated_part?.lowest_market_price * item.quantity) : null} bold={true} mono={true} size='s' />
                        <Label content={`${formatNumberWithCommas(item?.quantity)} x ${formatToCurrencyExpanded(item?.associated_part?.lowest_market_price)}`} size='s' mono={true} />
                    </div>
                    )}
                    {item.associated_part && !item.associated_part?.lowest_market_price && (
                        <Label content="No price data" size="s" bold={true} mono={true} secondary={true} />
                    )}
                    {!item.associated_part && item?.normalization_status !== "NO_MATCH" && (
                        <Label content="Processing" size="s" bold={true} mono={true} secondary={true} />
                    )}
                    {!item.associated_part && item?.normalization_status === "NO_MATCH" && (
                    //  primaryLabel: (item.associated_part && item.associated_part?.lowest_market_price && item.quantity) ? formatToCurrency(item.associated_part?.lowest_market_price * item.quantity) : null,
                    //             primaryMono: true,
                    //             secondaryLabel: `${formatNumberWithCommas(item?.quantity)} x ${formatToCurrencyExpanded(item?.associated_part?.lowest_market_price)}`,
                    //             padLeft: true,
                    //             processing: !item?.associated_part,
                    //             noMatch: item?.normalization_status === "NO_MATCH",
                    //             alignRight: true,
                    //             hidden: keystoneUserPreferencesHiddenColumns?.includes('Market Value')(
                        <Label content="No Match" size="s" bold={true} mono={true} secondary={true} />
                    )}
                </div>
            </div>);
    }, [sortedInventoryData]);

    const rowContent = useMemo(() => (
        <GroupedVirtuoso
            style={{ }}
            groupCounts={[sortedInventoryData.length]}
            groupContent={headerContent}
            components={{
                List: React.forwardRef(({style, children}, ref) => (
                    <div className="product-vertical-container list-container v-gap-s" style={{...style}} ref={ref}>
                        {children}
                    </div>
                ))
            }}
            itemContent={mapIndexToComponent}
        />),                
        [headerContent, mapIndexToComponent, sortedInventoryData.length]);

    useEffect(() => {
        if (selectedObject) {
            dispatch(openTrayTypeWithData({
                trayOpen: true,
                trayData: selectedObject,
                trayType: "product"
            }));
        }
    }, [dispatch, selectedObject]);
    return (
        <div className="product-vertical-container flex-1">
            {rowContent}
        </div>
    );
};

export default React.memo(InventoryList);
