import './style/Team.css';
import React, { useState, useEffect } from 'react';
import EllipsisCircle from '../../media/ellipsis-circle@3x.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateUserToDelete, updateUserToChange, updateRoleToChange } from '../../redux/keystoneSlice';
import { setShowModal, setModalType, normalizeTabName, deleteInvite } from '../../redux/keystoneInterfaceSlice';
import Row from './Row';
import Action from './Action';
import Label from './Label';
import { useNavigate, useParams } from 'react-router-dom';

const Team = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneUser = useSelector((state) => state?.keystone?.user);
    
    const keystoneInterfaceCalls = useSelector((state) => state?.keystoneInterface?.calls);
    const keystoneInventoryTeamMembers = useSelector((state) => state?.keystoneInventory?.team_members);
    const keystoneInventoryTeamInvitations = useSelector((state) => state?.keystoneInventory?.team_invitations);
    const keystoneInterfaceTeamTabs = useSelector((state) => state?.keystoneInterface?.teamTabs);

    const keystoneUserToChange = useSelector((state) => state?.keystone?.userToChange);
    const keystoneUserToDelete = useSelector((state) => state?.keystone?.userToDelete);
    
    const { page, tabName } = useParams();
    const currentTabIndex = keystoneInterfaceTeamTabs.map(normalizeTabName).indexOf(normalizeTabName(tabName));
    useEffect(() => {
        if (currentTabIndex < 0) {
            navigate(`/${page}/${normalizeTabName(keystoneInterfaceTeamTabs?.[0])}`);
        };
    }, [currentTabIndex, keystoneInterfaceTeamTabs, navigate, page]);
    

    const [inviteIndexLoading, setInviteIndexLoading] = useState(null);
    const [memberIndexLoading, setMemberIndexLoading] = useState(null);

    useEffect(() => {
        if (keystoneInterfaceCalls?.some(item =>
            item.type === "team" &&
            (item.action === "delete invite" || item.action === "update role") &&
            (item.status === "fulfilled" || item.status === "rejected")
        )) {
            setMemberIndexLoading(keystoneUserToChange?.index);
        }

        if (keystoneInterfaceCalls?.some(item =>
            item.type === "team" &&
            (item.action === "delete invite" || item.action === "update role") &&
            (item.status === "fulfilled" || item.status === "rejected")
        )) {
            setMemberIndexLoading(null);
            setInviteIndexLoading(null);
        }
    }, [keystoneUserToChange?.index, keystoneInterfaceCalls]);

    useEffect(() => {
        if (!keystoneUserToChange || !keystoneUserToDelete) {
            setMemberIndexLoading(null);
            setInviteIndexLoading(null);
        }
    }, [keystoneUserToChange, keystoneUserToDelete]);

    const handleChangeRole = (index, member) => {
        dispatch(setShowModal(true));
        dispatch(setModalType("change-user-role"));
        dispatch(
            updateUserToChange({
                index,
                member
            })
        )
        dispatch(
            updateRoleToChange({
                role: member.role === "VIEWER" ? "ADMIN" : "VIEWER"
            })
        )
    };

    const handleDeleteUser = (index, member) => {
        dispatch(setShowModal(true));
        dispatch(setModalType("delete-user"));
        dispatch(
            updateUserToDelete({
                index,
                member
            })
        )
    };

    const handleDeleteInvitation = (index, invitation) => {
        setInviteIndexLoading(index);
        dispatch(deleteInvite({ accessToken: keystoneInterfaceAccessToken, id: invitation?.id }));
    };

    return (
        <div className="keystone-offers product-horizontal-container space-between h-gap-l width-100">
            {page === "team" && (
                <div className="product-vertical-container list-container pad-m v-gap-s width-100">
                    <div className="product-vertical-container pad-m no-pad-bot">
                        <Label content={currentTabIndex === 0 ? "All Members" : currentTabIndex === 1 ? "Administrators" : "Viewers"} size="l" bold={true} displayFont={true} />
                    </div>
                    {keystoneInventoryTeamMembers?.filter(member => currentTabIndex === 0 ? member.role : currentTabIndex === 1 ? member.role === "ADMIN" : member.role === "VIEWER").map((member, index) => (
                        <Row
                            key={member.id}
                            index={member.id}
                            primaryLabel={member.first_name ? (member.first_name + " " + member.last_name) : member.email}
                            secondaryLabel={member.email}
                            tags={[{
                                label: member.role,
                                secondary: member.role === "VIEWER" ? true : false
                            }]}
                            button={keystoneUser?.role === "ADMIN" && {
                                icon: EllipsisCircle,
                                label: "",
                                loading: memberIndexLoading === index
                            }}
                            tooltip={true}
                            tooltipActions={keystoneUser?.role === "ADMIN" && (
                                <div>
                                    <Action onClick={() => handleChangeRole(index, member)} label={`Change to ${member.role === "VIEWER" ? "Admin" : "Viewer"}`} />
                                    {member.email !== keystoneUser?.email && <Action onClick={() => handleDeleteUser(index, member)} label="Remove from Team" />}
                                </div>
                            )} />  
                    ))}
                    <div className="product-vertical-container pad-m no-pad-bot">
                        <Label content="Pending Invitations" size="l" bold={true} displayFont={true} />
                    </div>
                    {keystoneInventoryTeamInvitations?.filter(invitation => currentTabIndex === 0 ? invitation.role : currentTabIndex === 1 ? invitation.role === "ADMIN" : invitation.role === "VIEWER").map((invitation, index) => (
                        <Row
                            key={invitation.id}
                            index={invitation.id}
                            primaryLabel={invitation.recipient.first_name ? `${invitation.recipient.first_name} ${invitation.recipient.last_name}` : invitation.recipient.email}
                            secondaryLabel={invitation.recipient.first_name ? invitation.recipient.email : ""}
                            tag={{
                                label: invitation.role,
                                secondary: invitation.role === "VIEWER" ? true : false
                            }}
                            button={keystoneUser?.role === "ADMIN" && {
                                icon: EllipsisCircle,
                                label: "",
                                loading: inviteIndexLoading === index
                            }}
                            tooltip={true}
                            tooltipActions={keystoneUser?.role === "ADMIN" && (
                                <Action onClick={() => handleDeleteInvitation(index, invitation)} label={"Delete Invitation"} negative={true} />
                            )} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default React.memo(Team);