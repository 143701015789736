import './style/CreateAccount.css';
import React, { useEffect, useState } from 'react';
import google from '../../media/google.png';
import ShieldDisplay from '../../media/shield-display-styled@3x.png';
import PowDisplay from '../../media/pow-display-styled@3x.png';
import Icon from './Icon';
import CheckmarkDotted from '../../media/checkmark-dotted-display-styled@3x.png';
import Divider from './Divider';
import { useSelector } from 'react-redux';
import SimpleLoader from './SimpleLoader';
import Label from './Label';

const CreateAccount = ({ handleCredentialAccountCreate, handleCredentialAccountCreateFromInvite, handleGoogleAccountCreate, handleGoogleAccountCreateFromInvite }) => {
    const keystoneInterfaceModalType = useSelector((state) => state?.keystoneInterface?.modalType);
    const keystoneInterfaceInviteVerified = useSelector((state) => state?.keystoneInterface?.inviteVerified);

    const [userEmail, setUserEmail] = useState(null);
    const [loginLoading, setLoginLoading] = useState(false);

    const handleCredentialAccountCreateClick = () => {
        setLoginLoading(true);

        if (keystoneInterfaceModalType === "create-account") {
            handleCredentialAccountCreate(userEmail);
        } else if (keystoneInterfaceModalType === "create-account-invite") {
            handleCredentialAccountCreateFromInvite(userEmail);
        }
    };

    const handleGoogleAccountCreateClick = () => {
        setLoginLoading(true);

        if (keystoneInterfaceModalType === "create-account") {
            handleGoogleAccountCreate();
        } else if (keystoneInterfaceModalType === "create-account-invite") {
            handleGoogleAccountCreateFromInvite();
        }
    };

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    useEffect(() => {
        if (keystoneInterfaceInviteVerified?.recipient?.email) {
            setUserEmail(keystoneInterfaceInviteVerified?.recipient?.email);
        }
    }, [keystoneInterfaceInviteVerified?.recipient?.email]);

    return (
        <div id="keystone-create-account" className={`${keystoneInterfaceModalType === "create-account-invite" ? "invite" : ""} `}>
            <div id="create-account-wrapper" className={`product-horizontal-container flex-1`}>
                {keystoneInterfaceModalType === "create-account" && (
                    <div id="create-account-details-pane" className="product-vertical-container flex-1 justify-center v-gap-display">
                        <div className="product-horizontal-container align-center h-gap-m">
                            <Icon icon={ShieldDisplay} size={64} mask={false} />
                            <div className="product-vertical-container">
                                <Label content="Privacy Guarantee" size="l" bold={true} />
                                <Label content="Corporately identifiable information is never shared." size="m" />
                            </div>
                        </div>
                        <div className="product-horizontal-container align-center h-gap-m">
                            <Icon icon={CheckmarkDotted} size={64} mask={false} />
                            <div className="product-vertical-container">
                                <Label content="No Commitment" size="l" bold={true} />
                                <Label content="You are not required to accept or enable offers on your inventory." size="m" />
                            </div>
                        </div>
                        <div className="product-horizontal-container align-center h-gap-m">
                            <Icon icon={PowDisplay} size={64} mask={false} />
                            <div className="product-vertical-container">
                                <Label content="Automatic Negotiation" size="l" bold={true} />
                                <Label content="We leverage market insights to reclaim you the most capital." size="m" />
                            </div>
                        </div>
                    </div>
                )}
                <div id="create-account-content-wrapper" className={`${keystoneInterfaceModalType === "create-account-invite" ? "invite" : ""} product-vertical-container flex-1`}>
                    <div id="create-account-header-wrapper" className="product-vertical-container flex-1 v-gap-s">
                        {keystoneInterfaceModalType === "create-account-invite" && keystoneInterfaceInviteVerified && (
                            <div className='product-vertical-container align-center flex-1 v-gap-xl'>
                                <Label content="You've been invited!" size="xl" bold={true} color="brand" />
                                <div className='product-vertical-container center-text align-center flex-1 v-gap-s'>
                                    <Label content={`${keystoneInterfaceInviteVerified?.sender?.first_name} has invited you to join their organization on Materialize:`} size="m" bold={true} secondary={true} invert={true} />
                                    <Label content={`${keystoneInterfaceInviteVerified?.company?.name}`} size="l" bold={true} invert={true} center={true} />
                                </div>
                            </div>
                        )}
                        {(keystoneInterfaceModalType !== "create-account-invite" || !keystoneInterfaceInviteVerified) && (
                            <>
                                <Label content="Create Account" size="xl" bold={true} color="brand" />
                                <Label content="Access free market insights and fee-based reclamation tools." size="m" bold={true} secondary={true} invert={true} />
                            </>)}
                    </div>
                    <div id="create-account-details-wrapper" className="product-vertical-container flex-1 align-center v-gap-xl">
                        <div className="product-vertical-container flex-1 width-100 v-gap-m">
                            <input readOnly={keystoneInterfaceModalType === 'create-account-invite'} id="create-account-email-input" className="sf-mono size-m" type="email" placeholder="Email Address" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}/>
                            <button className="create-account-button" onClick={handleCredentialAccountCreateClick} disabled={!userEmail || !isValidEmail(userEmail)}>
                                <Label content="Create Account" size="m" bold={true} displayFont={true} />
                            </button>
                        </div>
                        <Divider label="or" />
                        <div className="product-vertical-container flex-1 width-100 v-gap-l">
                            <button className="create-account-button with-logo relative" onClick={handleGoogleAccountCreateClick}>
                                <img src={google} alt="Google's 'G' Logo"/>
                                <Label content="Sign up with Google" size="m" bold={true} displayFont={true} />
                                <SimpleLoader loading={loginLoading} size={28} />
                                <img src={google} alt="Google's 'G' Logo"/>
                            </button>
                            <Label content="Note: Organization email address must be used." size="s" center={true} secondary={true} bold={true} displayFont={true} />
                        </div>
                        <a href='/privacy' id="create-account-privacy-anchor" className="size-s secondary">Privacy Policy</a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default React.memo(CreateAccount);