import './style/Placeholder.css';
import React from 'react';
import { } from '../../redux/keystoneSlice';
import { } from '../../redux/keystoneInventorySlice';
import Label from './Label';
import Icon from './Icon';
import AllCaughtUp from '../../media/all-caught-up72@3x.png';
const Placeholder = ({ label }) => {

    return (
        <div className="keystone-placeholder product-vertical-container v-gap-m flex-1 align-center justify-center">
            <Icon icon={AllCaughtUp} color="#6f7aa5" size={54} />
            <Label content={label} size="l" bold={true} secondary={true} displayFont={true} />
        </div>
    );
};

export default React.memo(Placeholder);