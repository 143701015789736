import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './appReducer';

// Preload state function
const preloadState = () => {
    const keystoneState = sessionStorage.getItem('keystoneState');
    const keystoneInventoryState = sessionStorage.getItem('keystoneInventoryState');

    return {
        keystone: keystoneState ? JSON.parse(keystoneState) : undefined,
        keystoneInventory: keystoneInventoryState ? JSON.parse(keystoneInventoryState) : undefined
    };
};

// Middleware to handle session storage updates
const sessionStorageMiddleware = storeAPI => next => action => {
    const result = next(action);
    const state = storeAPI.getState();

    try {
      sessionStorage.setItem('keystoneState', JSON.stringify(state.keystone));
      sessionStorage.setItem('keystoneInventoryState', JSON.stringify(state.keystoneInventory));
    } catch (e) {
      console.error(e);
    }

    return result;
};

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {},
        serializableCheck: false,
      }).concat(sessionStorageMiddleware),
    preloadedState: preloadState(),
});